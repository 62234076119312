:root {
  /* Light Mode Colors */
  background-color: #eceef2;
  --text-color-primary: #rgb(21, 21, 21);
  --text-color-secondary: #rgb(21, 21, 21);
  --primary-color: #494434;;
}

[data-theme="dark"] {
  /* Dark Mode Colors */
  --background-color: #202020;
  --text-color-primary: #ffffff;
  --text-color-secondary: #ffffff;
  --primary-color: #c6bda5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
